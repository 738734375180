<template>

    <zw-sidebar @shown="shown" :title="$t('bank.title.manual_bank_transfer')">
        <validation-observer tag="div" ref="observer">
            <b-overlay :show="loading" no-wrap></b-overlay>
            <div v-if="!loading">
                <b-row>
                    <b-col sm="12">
                        <zw-input-group v-model="form.account"
                                        name="account"
                                        type="text"
                                        :label-prefix="labelPrefix"
                                        validate="required"
                        ></zw-input-group>
                    </b-col>
                    <b-col sm="12">
                        <zw-input-group v-model="form.name"
                                        name="name"
                                        type="text"
                                        :label-prefix="labelPrefix"
                                        validate="required"
                        ></zw-input-group>
                    </b-col>
                    <b-col sm="12">
                        <zw-date-group v-model="form.date_time"
                                       name="date_time"
                                       :label-prefix="labelPrefix"
                                       validate="required"
                        ></zw-date-group>
                    </b-col>
                    <b-col sm="12">
                        <zw-input-group v-model="form.amount"
                                        name="amount"
                                        type="number"
                                        min="0"
                                        step="0.01"
                                        :label-prefix="labelPrefix"
                                        validate="required"
                        ></zw-input-group>
                    </b-col>
                    <b-col cols="12">
                        <zw-textarea-group v-model="form.description"
                                           name="description"
                                           :label-prefix="labelPrefix"
                        ></zw-textarea-group>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col sm="12" class="text-xs-center">
                        <b-row align-h="end">
                            <b-col sm="6" class="text-sm-right">
                                <b-button block @click="onSubmit()" variant="primary">
                                    {{ $t('common.button.save') }}
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-col>

                </b-row>
            </div>
        </validation-observer>
    </zw-sidebar>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'

export default {
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            labelPrefix: 'bank.label.',
            payload: {},
            callback: null,
            defaultForm: {},
            form: {
                is_credit:false
            },
        }
    },
    methods: {
        shown() {
            this.loading = false
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.loading = true
                    this.$store.dispatch('BankTransfers/create', this.form)
                        .then((response) => {
                            this.commonAfterSave(response)
                        })
                        .catch(errors => {
                            this.$refs['observer'].setErrors(errors)
                            this.showValidationError()
                        })
                        .finally(() => {
                            this.loading = false
                        })
                } else {
                    this.showValidationError()
                }
            })
        },
    }
}
</script>